<template>
  <v-row :dense="dense">
    <v-col>
      <pc-api-lookup-data-search
        :id="stateSelectId"
        v-if="showState()"
        label="State"
        v-model="state"
        :dense="dense"
        entity-type="State"
        :required="required"
        :disabled="disabled"
      ></pc-api-lookup-data-search>
      <pc-text-field
        :id="regionTextFieldId"
        v-else
        v-model="region"
        label="Region"
        :required="required"
        :dense="dense"
      ></pc-text-field>
    </v-col>
    <v-col>
      <pc-api-lookup-data-search
        :id="countrySelectId"
        label="Country"
        v-model="country"
        entity-type="Country"
        :required="required"
        :dense="dense"
        :disabled="disabled || disableCountry"
      ></pc-api-lookup-data-search>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    stateCode: {
      type: String,
    },
    otherRegion: {
      type: String,
    },
    countryCode: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    stateSelectId: {
      type: String,
      default: "",
    },
    countrySelectId: {
      type: String,
      default: "",
    },
    regionTextFieldId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    disableCountry: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      state: this.stateCode,
      region: this.otherRegion,
      country: this.countryCode,
    };
  },

  watch: {
    state(newValue) {
      if (!this.country) {
        this.country = "US";
      }
      this.$emit("selectionChange", {
        stateCode: newValue,
        otherRegion: this.otherRegion,
        countryCode: this.countryCode,
      });
    },
    stateCode(newValue) {
      this.state = newValue;
    },
    region(newValue) {
      this.$emit("selectionChange", {
        stateCode: this.state,
        otherRegion: newValue,
        countryCode: this.countryCode,
      });
    },
    otherRegion(newValue) {
      this.region = newValue;
    },
    country(newValue) {
      this.$emit("selectionChange", {
        stateCode: this.state,
        otherRegion: this.otherRegion,
        countryCode: newValue,
      });
    },
    countryCode(newValue) {
      this.country = newValue;
    },
  },

  methods: {
    showState() {
      return !this.country || this.country == "US";
    },
  },
};
</script>

<style lang="scss"></style>
