import PcActionChip from "@/shared/components/PcActionChip";
import PcAlert from "@/shared/components/PcAlert";
import PcAlertReskinned from "@/shared/components/PcAlertReskinned";
import PcApiEntitySearch from "@/shared/components/PcApiEntitySearch";
import PcApiEntitySearchText from "@/shared/components/PcApiEntitySearchText";
import PcApiLookupDataSearch from "@/shared/components/PcApiLookupDataSearch";
import PcApiLookupDataSearchReskinned from "@/shared/components/PcApiLookupDataSearchReskinned";
import PcApiLookupDataSelect from "@/shared/components/PcApiLookupDataSelect";
import PcApiLookupDataSelectReskinned from "@/shared/components/PcApiLookupDataSelectReskinned";
import PcApiLookupDataMultipleSelect from "@/shared/components/PcApiLookupDataMultipleSelect";
import PcApiLookupDataDisplay from "@/shared/components/PcApiLookupDataDisplay";
import PcBigIconButton from "@/shared/components/PcBigIconButton";
import PcBigToggle from "@/shared/components/PcBigToggle";
import PcBreadcrumbs from "@/shared/components/PcBreadcrumbs";
import PcButton from "@/shared/components/PcButton";
import PcMenuButton from "@/shared/components/PcMenuButton";
import PcCard from "@/shared/components/PcCard";
import PcCappedCard from "@/shared/components/PcCappedCard";
import PcCappedCard2 from "@/shared/components/PcCappedCard2";
import PcCappedModal from "@/shared/components/PcCappedModal";
import PcCheckbox from "@/shared/components/PcCheckbox";
import PcCheckmark from "@/shared/components/PcCheckmark";
import PcCheckExample from "@/shared/components/PcCheckExample";
import PcCopyable from "@/shared/components/PcCopyable";
import PcDataTable from "@/shared/components/PcDataTable";
import PcDateTextField from "@/shared/components/PcDateTextField";
import PcDateTextFieldReskinned from "@/shared/components/PcDateTextFieldReskinned";
import PcDatePicker from "@/shared/components/PcDatePicker";
import PcEnhancedDatePicker from "@/shared/components/PcEnhancedDatePicker";
import PcDropdown from "@/shared/components/PcDropdown";
import PcDropdownMultiple from "@/shared/components/PcDropdownMultiple";
import PcEmailAddressTextField from "@/shared/components/PcEmailAddressTextField";
import PcEmailAddressTextFieldReskinned from "@/shared/components/PcEmailAddressTextFieldReskinned";
import PcEmployerIdentificationNumberTextField from "@/shared/components/PcEmployerIdentificationNumberTextField";
import PcErrorAlert from "@/shared/components/PcErrorAlert";
import PcErrorAlertReskinned from "@/shared/components/PcErrorAlertReskinned";
import PcErrorBullet from "@/shared/components/PcErrorBullet";
import PcErrorList from "@/shared/components/PcErrorList";
import PcExpansionPanel from "@/shared/components/PcExpansionPanel";
import PcExpansionPanels from "@/shared/components/PcExpansionPanels";
import PcFileUploadModal from "@/shared/components/PcFileUploadModal";
import PcHorizontalTabs from "@/shared/components/PcHorizontalTabs";
import PcHorizontalTabsItem from "@/shared/components/PcHorizontalTabsItem";
import PcImage from "@/shared/components/PcImage";
import PcIcon from "@/shared/components/PcIcon";
import PcInPlaceEditor from "@/shared/components/PcInPlaceEditor";
import PcListPair from "@/shared/components/PcListPair";
import PcModal from "@/shared/components/PcModal";
import PcModalReskinned from "@/shared/components/PcModalReskinned";
import PcModelDrilldowns from "@/shared/components/PcModelDrilldowns";
import PcMoneyTextField from "@/shared/components/PcMoneyTextField";
import PcMoneyTextFieldReskinned from "@/shared/components/PcMoneyTextFieldReskinned";
import PcNoticeName from "@/shared/components/PcNoticeName";
import PcPasswordTextField from "@/shared/components/PcPasswordTextField";
import PcPasswordTextFieldReskinned from "@/shared/components/PcPasswordTextFieldReskinned";
import PcPdfViewer from "@/shared/components/PcPdfViewer";
import PcPercentageTextField from "@/shared/components/PcPercentageTextField";
import PcPercentageTextFieldReskinned from "@/shared/components/PcPercentageTextFieldReskinned";
import PcPhoneNumberTextField from "@/shared/components/PcPhoneNumberTextField";
import PcPhoneNumberTextFieldReskinned from "@/shared/components/PcPhoneNumberTextFieldReskinned";
import PcPostalCodeTextField from "@/shared/components/PcPostalCodeTextField";
import PcPostalCodeTextFieldReskinned from "@/shared/components/PcPostalCodeTextFieldReskinned";
import PcRadio from "@/shared/components/PcRadio";
import PcRadioGroup from "@/shared/components/PcRadioGroup";
import PcRoutingNumber from "@/shared/components/PcRoutingNumber";
import PcRoutingNumberReskinned from "@/shared/components/PcRoutingNumberReskinned";
import PcScrollingPrompts from "@/shared/components/PcScrollingPrompts";
import PcScrollingPromptsItem from "@/shared/components/PcScrollingPromptsItem";
import PcSelectCard from "@/shared/components/PcSelectCard";
import PcSelectCardReskinned from "@/shared/components/PcSelectCardReskinned";
import PcSocialSecurityNumberTextField from "@/shared/components/PcSocialSecurityNumberTextField";
import PcSocialSecurityNumberTextFieldReskinned from "@/shared/components/PcSocialSecurityNumberTextFieldReskinned";
import PcSpinner from "@/shared/components/PcSpinner";
import PcStateCountrySelect from "@/shared/components/PcStateCountrySelect";
import PcStaticAddressValue from "@/shared/components/PcStaticAddressValue";
import PcStaticAddressValueReskinned from "@/shared/components/PcStaticAddressValueReskinned";
import PcStaticFieldValue from "@/shared/components/PcStaticFieldValue";
import PcStaticFileValue from "@/shared/components/PcStaticFileValue";
import PcStaticFileValueReskinned from "@/shared/components/PcStaticFileValueReskinned";
import PcTaxId from "@/shared/components/PcTaxId";
import PcStatusChip from "@/shared/components/PcStatusChip";
import PcStatusDetail from "@/shared/components/PcStatusDetail";
import PcSurveyModal from "@/shared/components/PcSurveyModal";
import PcFixedHeaderLayout from "@/shared/components/PcFixedHeaderLayout";
import PcFlag from "@/shared/components/PcFlag";
import PcTextarea from "@/shared/components/PcTextarea";
import PcTextField from "@/shared/components/PcTextField";
import PcTextFieldReskinned from "@/shared/components/PcTextFieldReskinned";
import PcToggle from "@/shared/components/PcToggle";
import PcTimePicker from "@/shared/components/PcTimePicker";
import PcTooltip from "@/shared/components/PcTooltip";
import PcWindow from "@/shared/components/PcWindow";
import PcWindowItem from "@/shared/components/PcWindowItem";
import PcWorksheet from "@/shared/components/PcWorksheet";
import PcWorksheetSection from "@/shared/components/PcWorksheetSection";
import PcEnhancedFieldSelector from "@/shared/components/PcEnhancedFieldSelector";
import PcFieldSelector from "@/shared/components/PcFieldSelector";
import PcSnackBarReskinned from "@/shared/components/PcSnackBarReskinned";
import PcDropdownReskinned from "@/shared/components/PcDropdownReskinned";
import PcDragDropFileUpload from "@/shared/components/PcDragDropFileUpload";

const DefaultReusableComponents = {
  install(Vue) {
    Vue.component("pc-action-chip", PcActionChip);
    Vue.component("pc-alert", PcAlert);
    Vue.component("pc-alert-reskinned", PcAlertReskinned);
    Vue.component("pc-api-entity-search", PcApiEntitySearch);
    Vue.component("pc-api-entity-search-text", PcApiEntitySearchText);
    Vue.component("pc-api-lookup-data-search", PcApiLookupDataSearch);
    Vue.component(
      "pc-api-lookup-data-search-reskinned",
      PcApiLookupDataSearchReskinned
    );
    Vue.component("pc-api-lookup-data-select", PcApiLookupDataSelect);
    Vue.component(
      "pc-api-lookup-data-select-reskinned",
      PcApiLookupDataSelectReskinned
    );
    Vue.component(
      "pc-api-lookup-data-multiple-select",
      PcApiLookupDataMultipleSelect
    );
    Vue.component("pc-api-lookup-data-display", PcApiLookupDataDisplay);
    Vue.component("pc-big-icon-button", PcBigIconButton);
    Vue.component("pc-button", PcButton);
    Vue.component("pc-menu-button", PcMenuButton);
    Vue.component("pc-big-toggle", PcBigToggle);
    Vue.component("pc-breadcrumbs", PcBreadcrumbs);
    Vue.component("pc-card", PcCard);
    Vue.component("pc-capped-card", PcCappedCard);
    Vue.component("pc-capped-card-2", PcCappedCard2);
    Vue.component("pc-capped-modal", PcCappedModal);
    Vue.component("pc-checkbox", PcCheckbox);
    Vue.component("pc-checkmark", PcCheckmark);
    Vue.component("pc-check-example", PcCheckExample);
    Vue.component("pc-copyable", PcCopyable);
    Vue.component("pc-data-table", PcDataTable);
    Vue.component("pc-date-text-field", PcDateTextField);
    Vue.component("pc-date-text-field-reskinned", PcDateTextFieldReskinned);
    Vue.component("pc-date-picker", PcDatePicker);
    Vue.component("pc-enhanced-date-picker", PcEnhancedDatePicker);
    Vue.component("pc-dropdown", PcDropdown);
    Vue.component("pc-dropdown-multiple", PcDropdownMultiple);
    Vue.component("pc-error-alert", PcErrorAlert);
    Vue.component("pc-error-alert-reskinned", PcErrorAlertReskinned);
    Vue.component("pc-error-bullet", PcErrorBullet);
    Vue.component("pc-error-list", PcErrorList);
    Vue.component("pc-email-address-text-field", PcEmailAddressTextField);
    Vue.component(
      "pc-email-address-text-field-reskinned",
      PcEmailAddressTextFieldReskinned
    );
    Vue.component(
      "pc-employer-identification-number-text-field",
      PcEmployerIdentificationNumberTextField
    );
    Vue.component("pc-expansion-panel", PcExpansionPanel);
    Vue.component("pc-expansion-panels", PcExpansionPanels);
    Vue.component("pc-file-upload-modal", PcFileUploadModal);
    Vue.component("pc-horizontal-tabs", PcHorizontalTabs);
    Vue.component("pc-horizontal-tabs-item", PcHorizontalTabsItem);
    Vue.component("pc-image", PcImage);
    Vue.component("pc-icon", PcIcon);
    Vue.component("pc-in-place-editor", PcInPlaceEditor);
    Vue.component("pc-list-pair", PcListPair);
    Vue.component("pc-modal", PcModal);
    Vue.component("pc-modal-reskinned", PcModalReskinned);
    Vue.component("pc-model-drilldowns", PcModelDrilldowns);
    Vue.component("pc-money-text-field", PcMoneyTextField);
    Vue.component("pc-money-text-field-reskinned", PcMoneyTextFieldReskinned);
    Vue.component("pc-notice-name", PcNoticeName);
    Vue.component("pc-password-text-field", PcPasswordTextField);
    Vue.component(
      "pc-password-text-field-reskinned",
      PcPasswordTextFieldReskinned
    );
    Vue.component("pc-pdf-viewer", PcPdfViewer);
    Vue.component("pc-percentage-text-field", PcPercentageTextField);
    Vue.component(
      "pc-percentage-text-field-reskinned",
      PcPercentageTextFieldReskinned
    );
    Vue.component("pc-phone-number-text-field", PcPhoneNumberTextField);
    Vue.component(
      "pc-phone-number-text-field-reskinned",
      PcPhoneNumberTextFieldReskinned
    );
    Vue.component("pc-postal-code-text-field", PcPostalCodeTextField);
    Vue.component(
      "pc-postal-code-text-field-reskinned",
      PcPostalCodeTextFieldReskinned
    );
    Vue.component("pc-radio", PcRadio);
    Vue.component("pc-radio-group", PcRadioGroup);
    Vue.component("pc-routing-number", PcRoutingNumber);
    Vue.component("pc-routing-number-reskinned", PcRoutingNumberReskinned);
    Vue.component("pc-scrolling-prompts", PcScrollingPrompts);
    Vue.component("pc-scrolling-prompts-item", PcScrollingPromptsItem);
    Vue.component("pc-select-card", PcSelectCard);
    Vue.component("pc-select-card-reskinned", PcSelectCardReskinned);
    Vue.component("pc-spinner", PcSpinner);
    Vue.component(
      "pc-social-security-number-text-field",
      PcSocialSecurityNumberTextField
    );
    Vue.component(
      "pc-social-security-number-text-field-reskinned",
      PcSocialSecurityNumberTextFieldReskinned
    );
    Vue.component("pc-state-country-select", PcStateCountrySelect);
    Vue.component("pc-static-address-value", PcStaticAddressValue);
    Vue.component(
      "pc-static-address-value-reskinned",
      PcStaticAddressValueReskinned
    );
    Vue.component("pc-static-field-value", PcStaticFieldValue);
    Vue.component("pc-static-file-value", PcStaticFileValue);
    Vue.component("pc-static-file-value-reskinned", PcStaticFileValueReskinned);
    Vue.component("pc-tax-id", PcTaxId);
    Vue.component("pc-status-chip", PcStatusChip);
    Vue.component("pc-status-detail", PcStatusDetail);
    Vue.component("pc-survey-modal", PcSurveyModal);
    Vue.component("pc-fixed-header-layout", PcFixedHeaderLayout);
    Vue.component("pc-flag", PcFlag);
    Vue.component("pc-textarea", PcTextarea);
    Vue.component("pc-text-field", PcTextField);
    Vue.component("pc-text-field-reskinned", PcTextFieldReskinned);
    Vue.component("pc-toggle", PcToggle);
    Vue.component("pc-time-picker", PcTimePicker);
    Vue.component("pc-tooltip", PcTooltip);
    Vue.component("pc-window", PcWindow);
    Vue.component("pc-window-item", PcWindowItem);
    Vue.component("pc-worksheet", PcWorksheet);
    Vue.component("pc-worksheet-section", PcWorksheetSection);
    Vue.component("pc-enhanced-field-selector", PcEnhancedFieldSelector);
    Vue.component("pc-field-selector", PcFieldSelector);
    Vue.component("pc-snack-bar-reskinned", PcSnackBarReskinned);
    Vue.component("pc-dropdown-reskinned", PcDropdownReskinned);
    Vue.component("pc-drag-drop-file-upload", PcDragDropFileUpload);
  },
};

export default DefaultReusableComponents;
