var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'social-security-number-text-field',
      _vm.isParticipantPortalField
        ? 'is-participant-portal-field'
        : 'is-general-field',
      { 'is-account-holder-portal-field': _vm.isAccountHolderField },
    ]},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"social-security-number-text-field-header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{ref:"textField",staticClass:"participant-field multi-line",attrs:{"rules":[_vm.validateTaxId],"type":!_vm.isObfuscated ? 'text' : 'password',"label":_vm.isParticipantPortalField ? '' : _vm.label + (_vm.required ? '' : ' (optional)'),"placeholder":_vm.isParticipantPortalField ? _vm.placeholder : '',"error-messages":_vm.errorMessages,"validate-on-blur":"","disabled":_vm.disabled,"clearable":_vm.clearable,"maxlength":"11","dense":_vm.dense,"loading":_vm.loading,"color":_vm.isParticipantPortalField ? _vm.$colors.default : _vm.$colors.penChecksTeal,"outlined":_vm.isParticipantPortalField},on:{"blur":_vm.blur,"keyup":_vm.textChanged},scopedSlots:_vm._u([(_vm.isParticipantPortalField)?{key:"append",fn:function(){return [_c('button',{on:{"click":_vm.toggleView}},[(_vm.isObfuscated)?_c('pc-view-icon',{attrs:{"size":"20"}}):_vm._e(),(!_vm.isObfuscated)?_c('pc-view-icon',{attrs:{"type":"off","size":"20"}}):_vm._e()],1)]},proxy:true}:null],null,true),model:{value:(_vm.formattedSocialSecurityNumber),callback:function ($$v) {_vm.formattedSocialSecurityNumber=$$v},expression:"formattedSocialSecurityNumber"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }