var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'postal-code-text-field',
      _vm.isParticipantPortalField
        ? 'is-participant-portal-field'
        : 'is-general-field',
      { 'is-account-holder-portal-field': _vm.isAccountHolderField },
    ]},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"postal-code-text-field-header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{attrs:{"id":_vm.id,"label":_vm.isParticipantPortalField
          ? ''
          : _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)'),"placeholder":_vm.placeholder,"required":_vm.required,"rules":_vm.rules,"disabled":_vm.disabled,"clearable":_vm.clearable,"dense":_vm.dense,"validate-on-blur":true,"color":_vm.$colors.penChecksTeal,"outlined":_vm.isParticipantPortalField},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }