var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      _vm.fullWidth ? 'pc-dropdown-container-full-width' : 'pc-dropdown-container',
    ]},[(_vm.showHeader)?_c('div',{staticClass:"header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:{
            'error--text': _vm.hasError,
            'pc-dropdown': !_vm.fieldsMenu,
            'pc-dropdown-clicked': _vm.fieldsMenu,
          },attrs:{"outlined":"","ripple":false,"width":_vm.calculateWidth,"disabled":_vm.disabled,"id":_vm.id}},'v-btn',attrs,false),on),[(_vm.setInitialValue && _vm.selectedIndex < 0)?_c('span',{staticClass:"select-initial-style"},[_vm._v("Select")]):_vm._e(),_c('v-row',{staticClass:"pl-0 ml-0",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pl-0 ml-4",attrs:{"align":"left"}},[(_vm.selectedIndex >= 0)?_c('span',{staticClass:"selected-item-style"},[_vm._v(_vm._s(_vm.showText ? _vm.selectedText : _vm.value))]):_vm._e()]),_c('v-col',{staticClass:"pr-2 chevron-top",attrs:{"align":"right","justify":"right"}},[(!_vm.fieldsMenu)?_c('img',{attrs:{"height":"16px","src":require("@/participant/assets/img/chevron-up.svg")}}):_vm._e(),(_vm.fieldsMenu)?_c('img',{attrs:{"height":"16px","src":require("@/participant/assets/img/chevron-down.svg")}}):_vm._e()])],1)],1)]}}]),model:{value:(_vm.fieldsMenu),callback:function ($$v) {_vm.fieldsMenu=$$v},expression:"fieldsMenu"}},[_c('v-list',{staticClass:"pc-dropdown-list v-list-new",attrs:{"dense":"","width":_vm.calculateWidth,"height":_vm.height}},_vm._l((_vm.items),function(item,$index){return _c('v-list-item',{key:item.value,class:{
            'pc-dropdown-selected-item': $index == _vm.selectedIndex,
            'pc-dropdown-item': true,
          },attrs:{"id":"no-background-hover","disabled":!!item.disabled,"ripple":false},on:{"click":function($event){return _vm.clickedIndex($index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',{staticClass:"pc-dropdown-checkmark"},[_c('pc-checkmark-icon',{directives:[{name:"show",rawName:"v-show",value:($index == _vm.selectedIndex),expression:"$index == selectedIndex"}],attrs:{"icon-color-class":"checkmark-color"}})],1)],1)}),1)],1),(_vm.required && _vm.hasError)?_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"error-text"},[_vm._v("Select a "+_vm._s(_vm.header.toLocaleLowerCase()))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }