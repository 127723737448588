var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'email-address-text-field',
      _vm.isParticipantPortalField
        ? 'is-participant-portal-field'
        : 'is-general-field',
      { 'is-account-holder-portal-field': _vm.isAccountHolderField },
    ]},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"email-address-text-field-header-style"},[(_vm.required && !_vm.isAccountHolderField)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{style:(`--custom-border-color: ${_vm.borderColor}`),attrs:{"rules":_vm.emailAddressRules,"label":_vm.isParticipantPortalField
          ? ''
          : _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)'),"placeholder":_vm.placeholder,"validate-on-blur":"","error-messages":_vm.errors,"color":_vm.$colors.penChecksTeal,"dense":_vm.dense,"disabled":_vm.disabled,"clearable":_vm.clearable,"required":_vm.required,"outlined":_vm.isParticipantPortalField},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter', $event)},"focus":_vm.inputFocused},scopedSlots:_vm._u([(_vm.isParticipantPortalField && !_vm.hideIcon)?{key:"prepend-inner",fn:function(){return [_c('pc-email-icon',{staticClass:"email-address-text-icon-style"})]},proxy:true}:null],null,true),model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }