<template>
  <ul>
    <li v-for="item in items">
      {{ item }}
    </li>
  </ul>
</template>
<script>
export default {
  components: {},

  props: {
    fieldsRequiringDataFix: {
      type: String,
    },
  },

  computed: {
    items: function () {
      var errors = [];

      if (!this.fieldsRequiringDataFix) {
        return errors;
      }

      try {
        var data = JSON.parse(this.fieldsRequiringDataFix);

        for (var key in data) {
          var section = data[key];

          if (typeof section == "string") {
            errors.push(section);
            continue;
          }

          for (var key2 in section) errors.push(section[key2]);
        }
      } catch (err) {
        return;
      }

      return errors;
    },
  },
};
</script>

<style lang="scss">
.custom-list-item {
  list-style-type: disc; /* Adds bullet points */
  margin-left: 20px; /* Indents the bullet points */
}
</style>
