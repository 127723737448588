"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import $ from "jquery";
import Vue from "vue";
import User from "@/shared/lib/client-sdk/models/user";

class Participant extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Participant";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants"
    );
  }

  getApiPathForCreate() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.subaccountId +
      "/participants"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/" +
      this.id
    );
  }

  getAdminPath() {
    return "/participants/" + this.id;
  }

  get searchResultTitle() {
    return this.fullName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    var taxId = "";

    if (this.taxId && this.taxIdType !== "employerIdentificationNumber") {
      taxId = this.taxId.replace(/(\w{3})(\w{2})(\w{4})/, "$1-$2-$3");
    } else if (this.taxId) {
      taxId = this.taxId.replace(/(\w{2})(\w{7})/, "$1-$2");
    }

    return taxId;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  get fullName() {
    if (this.taxIdType == "employerIdentificationNumber") {
      return this.companyName;
    }

    var firstNameCheck = this.firstName;
    if (!firstNameCheck || firstNameCheck.trim() == "") return "[Missing name]";

    var lastNameCheck = this.lastName;
    var last =
      lastNameCheck && lastNameCheck.trim() != "" ? " " + lastNameCheck : "";

    return firstNameCheck + last;
  }

  get fullNameChangeRequest() {
    if (this.taxIdType == "employerIdentificationNumber") {
      return this.companyName;
    }

    var firstNameCheck = this.firstNameChangeRequest
      ? this.firstNameChangeRequest
      : this.firstName;

    if (!firstNameCheck || firstNameCheck.trim() == "") return "[Missing name]";

    var lastNameCheck = this.lastNameChangeRequest
      ? this.lastNameChangeRequest
      : this.lastName;

    var last =
      lastNameCheck && lastNameCheck.trim() != "" ? " " + lastNameCheck : "";

    return firstNameCheck + last;
  }

  get hasPersonalInformationChangeRequest() {
    return (
      this.taxIdTypeChangeRequest ||
      this.taxIdChangeRequest ||
      this.firstNameChangeRequest ||
      this.lastNameChangeRequest
    );
  }

  getTaxIdTypes(
    orderServiceCode,
    totalOrderLines,
    containsW8BenOrderLines,
    distributionReason,
    hasPermissionToSetEinForDeathReason
  ) {
    const disabledNonW8BenText =
      "You may only add W-8BEN participants to this order.";
    let taxIdTypes = [
      {
        value: "nonResidentAlienTaxId",
        text: "Non-resident Alien",
        disabled: containsW8BenOrderLines,
        disabledToolTipText: disabledNonW8BenText,
      },
      {
        value: "residentAlienTaxId",
        text: "Resident Alien",
        disabled: containsW8BenOrderLines,
        disabledToolTipText: disabledNonW8BenText,
      },
    ];

    const ein = {
      value: "employerIdentificationNumber",
      text: "Employer Identification Number",
      disabled: containsW8BenOrderLines,
      disabledToolTipText: disabledNonW8BenText,
    };

    const w8ben = {
      value: "w8Ben",
      text: "W-8BEN*",
      disabled:
        totalOrderLines != null &&
        totalOrderLines > 0 &&
        !containsW8BenOrderLines,
      disabledToolTipText:
        "W-8BEN may not be selected if there are non-W-8BEN participants in this order.",
    };

    let einAlreadyIncluded = false;
    if (
      orderServiceCode === "taxPaymentsAndForms" ||
      orderServiceCode === "taxFormsOnly"
    ) {
      einAlreadyIncluded = true;
      taxIdTypes.push(ein);
    }

    if (
      !einAlreadyIncluded &&
      hasPermissionToSetEinForDeathReason &&
      distributionReason === "death" &&
      [
        "premierDistributions",
        "expressDistributions",
        "uncashedChecksQualified",
        "uncashedChecksNonQualified",
        "vendorDistributions",
      ].includes(orderServiceCode)
    ) {
      einAlreadyIncluded = true;
      taxIdTypes.push(ein);
    }

    if (!orderServiceCode || orderServiceCode === "expressDistributions") {
      if (!einAlreadyIncluded) {
        einAlreadyIncluded = true;
        taxIdTypes.push(ein);
      }
      taxIdTypes.push(w8ben);
    }

    return taxIdTypes;
  }

  getTaxIdTypeLabel() {
    switch (this.taxIdType) {
      case "nonResidentAlienTaxId":
        return "Non-resident Tax ID";
      case "residentAlienTaxId":
        return "Resident Alien Tax ID";
      case "employerIdentificationNumber":
        return "Employer Identification Number";
      case "w8Ben":
        return "Foreign Tax ID (W-8BEN)";
      default:
        return "Social Security Number";
    }
  }

  static fetchMyDistributions(callback) {
    var requestUrl = this.getApiPathForSearch() + "/my-distributions";

    const participant = new Participant();
    participant.refresh(
      function (response) {
        if (response.successful) {
          callback(response.result);
        } else {
          callback(false);
        }
      }.bind(this),
      requestUrl
    );
  }

  attemptLogin(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/login";

    var requestBody = super.getAttributes();

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static createLogin(emailAddress, password, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/register";

    var requestData = {
      emailAddress,
      password,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful && response.result) {
          var user = new User();
          user._loadAttributes(response.result);
          user._makeClean();

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static verifyEmailAddress(newEmailAddress, oldEmailAddress, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/verify-email-address";

    newEmailAddress = newEmailAddress.trim();

    var requestData = {
      newEmailAddress,
      oldEmailAddress,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static confirmEmailAddressByCode(
    emailAddress,
    emailConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/confirm-email-address-by-code";

    var requestData = {
      emailAddress: emailAddress.trim(),
      emailConfirmationCode: emailConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static confirmChangeEmailAddressByCode(
    userName,
    emailConfirmationCode,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/confirm-change-email-address-by-code";

    var requestData = {
      userName: userName,
      emailConfirmationCode: emailConfirmationCode,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if (response.result.authentication) {
            const authToken = response.result.authentication.token;

            Vue.$cookies.set(
              "authToken",
              authToken,
              Infinity,
              "/",
              window.amplifyRuntimeConfig.get("VUE_APP_AMPLIFY_COOKIE_DOMAIN")
            );
          }

          const user = new User(response.result.user);

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static setPassword(emailAddress, password, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/setPassword";

    var requestData = {
      emailAddress,
      password,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful && response.result) {
          var user = new User();
          user._loadAttributes(response.result);
          user._makeClean();

          if ($.isFunction(callback)) callback(user, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static changeEmailAddress(
    newEmailAddress,
    changeEmailAddressToken,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/users/authenticated-participant-user/change-email-address";

    var requestData = {
      newEmailAddress,
      changeEmailAddressToken,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  verify(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/" +
      this.id +
      "/verify";

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  verifyNextLevel(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/" +
      this.id +
      "/next-level";

    ApiRequest.send("POST", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  loadIdentityVerificationResult(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/" +
      this.id +
      "/identity-verification-result";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  static uploadAutoParticipants(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/participants/upload-auto-participants";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  get hasInternationalAddress() {
    return this.countryCode && this.countryCode != "US";
  }

  static calculateRetirementBenefit(
    orderLineId,
    currentAge,
    retirementAge,
    retirementFunds,
    assumedRateOfReturn,
    callback
  ) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/participants/calculate-retirement-benefit";

    var requestData = {
      orderLineId,
      currentAge,
      retirementAge,
      retirementFunds,
      assumedRateOfReturn,
    };

    ApiRequest.send("POST", requestUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback))
            callback(response.result, response.message, null);
        } else {
          if ($.isFunction(callback))
            callback(null, response.message, response);
        }
      }.bind(this)
    );
  }

  static searchTaxForms(taxYear, callback) {
    var requestUrl =
      this.getApiPathForSearch() + "/tax-forms?taxYear=" + taxYear;

    ApiRequest.send("GET", requestUrl, null).onComplete(function (response) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  static downloadTaxForms(forTaxFormSearchId, taxFormRequest, callback) {
    var requestUrl =
      this.getApiPathForSearch() +
      "/tax-forms/" +
      forTaxFormSearchId +
      "/download";

    var requestBody = taxFormRequest;

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }
}

export default Participant;
