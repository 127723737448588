<template>
  <div
    :class="[
      'social-security-number-text-field',
      isParticipantPortalField
        ? 'is-participant-portal-field'
        : 'is-general-field',
      { 'is-account-holder-portal-field': isAccountHolderField },
    ]"
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="social-security-number-text-field-header-style"
    >
      <span v-if="required && !isAccountHolderField" class="error-text">*</span
      >{{ header }}
    </div>
    <v-text-field
      ref="textField"
      v-model="formattedSocialSecurityNumber"
      :rules="[validateTaxId]"
      :type="!isObfuscated ? 'text' : 'password'"
      :label="
        isParticipantPortalField ? '' : label + (required ? '' : ' (optional)')
      "
      :placeholder="isParticipantPortalField ? placeholder : ''"
      :error-messages="errorMessages"
      validate-on-blur
      :disabled="disabled"
      :clearable="clearable"
      maxlength="11"
      :dense="dense"
      :loading="loading"
      :color="
        isParticipantPortalField ? $colors.default : $colors.penChecksTeal
      "
      class="participant-field multi-line"
      :outlined="isParticipantPortalField"
      @blur="blur"
      @keyup="textChanged"
    >
      <template v-if="isParticipantPortalField" v-slot:append>
        <button @click="toggleView">
          <pc-view-icon v-if="isObfuscated" size="20" />
          <pc-view-icon type="off" v-if="!isObfuscated" size="20" />
        </button>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import PcViewIcon from "@/shared/components/design-system/PcViewIcon";

export default {
  name: "PcSocialSecurityNumberTextFieldReskinned",

  components: {
    PcViewIcon,
  },
  props: {
    value: {
      // Should be the unmasked social security number
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "Social Security Number",
    },
    placeholder: {
      type: String,
      default: "Enter your SSN or ITIN",
    },
    forNonResidentAliens: {
      type: Boolean,
      default: false,
    },
    allowSsnOrNonResidentAlien: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Social Security Number",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
    errorLabel: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      formattedSocialSecurityNumber: null,
      isObfuscated: this.isParticipantPortalField ? true : false,
      errorMessages: null,
    };
  },

  watch: {
    formattedSocialSecurityNumber() {
      this.$emit("input", this.nineNumberValue);
    },

    value() {
      if (this.value != this.nineNumberValue) {
        this.formattedSocialSecurityNumber = this.value;
        this.formatText();
        this.$refs.textField.validate();
      }
    },
  },

  computed: {
    nineNumberValue: function () {
      if (
        this.formattedSocialSecurityNumber == null ||
        this.formattedSocialSecurityNumber.trim() == ""
      )
        return null;

      var socialSecurityNumber = this.formattedSocialSecurityNumber
        .replace(/[^\d]/g, "")
        .substr(0, 9);
      if (socialSecurityNumber.length == 9) return socialSecurityNumber;

      return null;
    },
  },

  methods: {
    validateTaxId: function (value) {
      if (
        this.forNonResidentAliens ||
        this.header == "Individual Taxpayer Identification Number"
      )
        return this.validateITIN(value);
      return this.validateSSN(value);
    },
    validateSSN: function (value) {
      if (this.required && !value)
        return this.isAccountHolderField
          ? this.errorLabel
          : "Please enter a value";

      if (value && value.length != 11)
        return "Please enter a valid social security number";

      if (!value || value.trim() == "") return true;

      const socialSecurityNumberPattern =
        /^([0-8][0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      const nonResidentAlienPattern = /^(9[0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      const ssnOrNonResidentAlienPattern =
        /^([0-9][0-9]{2})-?([0-9]{2})-?([0-9]{4})/;
      var regex;
      if (this.$vnode.key === "ssn") regex = socialSecurityNumberPattern;
      else if (this.$vnode.key === "itin" || this.allowSsnOrNonResidentAlien)
        regex = ssnOrNonResidentAlienPattern;
      else
        regex = this.forNonResidentAliens
          ? nonResidentAlienPattern
          : socialSecurityNumberPattern;
      const matches = value.match(regex);

      if (!matches || matches.length != 4)
        return "Please enter a valid social security number";

      var fullNumber = matches[1] + matches[2] + matches[3];

      // Check for common fake SSNs, and sequences otherwise not covered
      var invalidNumbers = [
        "078051120",
        "219099999",
        "123121234",
        "111223333",
        "111121234",
        "321214321",
      ];

      if (
        matches[1] == "000" ||
        matches[2] == "00" ||
        matches[3] == "0000" || // Any segments with all zeros is invalid
        matches[1] == "666" || // Cannot start with 666
        fullNumber.includes("1234567") || // Sequences of 7 digits
        fullNumber.includes("2345678") ||
        fullNumber.includes("3456789") ||
        fullNumber.includes("9876543") ||
        fullNumber.includes("8765432") ||
        fullNumber.includes("7654321") ||
        fullNumber.match(
          /(012|123|234|345|456|567|678|789)(01|12|23|34|45|56|67|78|89)(0123|1234|2345|3456|4567|5678|6789)/
        ) || // 3 consecutive numbers, 2 consecutive numbers, 4 consecutive numbers
        fullNumber.match(
          /(987|876|765|654|543|432|321|210)(98|87|76|65|54|43|32|21|10)(9876|8765|7654|6543|5432|4321|3210)/
        ) || // 3 reverse consecutive numbers, 2 reverse consecutive numbers, 4 reverse consecutive numbers
        fullNumber.match(/([0-9]{1})\1\1\1\1\1\1/) || // If you have 7 or more of the same digits repeating, probably fake
        fullNumber.match(/([0-9]{3})\1\1/) || // Duplicating a sequence of 3 digits, 3 times (eg. 123123123 or 927927927)
        fullNumber.match(/([0-9]{2})\1\1\1/) || // A pair of numbers, duplicated 4 times (eg. 121212122 or 929292922)
        fullNumber.match(/([0-9])\1\1([0-9])\2([0-9])\3\3\3/) || // First 3 are the same, second 2 are the same, and the last 4 are the same digit
        invalidNumbers.includes(fullNumber)
      ) {
        return "Please enter a valid social security number";
      }

      return true;
    },
    validateITIN(val) {
      if (this.required && !val) {
        return "Enter an individual taxpayer identification number";
      }

      val = (" " + val).trim().replace(/-/g, "");

      if (val.length > 0 && val.length !== 9) {
        return "Individual taxpayer identification number must be nine digits";
      }

      if (val.length >= 1) {
        if (!val.startsWith("9")) {
          return "Individual taxpayer identification number must begin with 9";
        }
      }

      return true;
    },
    textChanged: function (e) {
      var selectionStart = e.target.selectionStart;
      var selectionEnd = e.target.selectionEnd;
      var value = e.target.value;

      if (
        this.formattedSocialSecurityNumber != null &&
        this.formattedSocialSecurityNumber.trim() == ""
      ) {
        this.formattedSocialSecurityNumber = null;
        return;
      }

      var isPasting = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() == "v";

      // Dont format if not at end of string (or pasting)
      if (
        !isPasting &&
        (selectionStart != selectionEnd || selectionStart < value.length)
      ) {
        return;
      }

      this.formatText();
    },

    blur: function () {
      this.formatText();
      this.$emit("blur");
    },
    toggleView: function (e) {
      this.isObfuscated = !this.isObfuscated;
      e.preventDefault();
    },
    formatText: function () {
      if (
        this.formattedSocialSecurityNumber == null ||
        this.formattedSocialSecurityNumber.trim() == ""
      )
        return;

      var numbersOnlyTextValue = this.formattedSocialSecurityNumber.replace(
        /[^\d]/g,
        ""
      );

      this.formattedSocialSecurityNumber = numbersOnlyTextValue.substr(0, 3);
      if (numbersOnlyTextValue.length > 3)
        this.formattedSocialSecurityNumber +=
          "-" + numbersOnlyTextValue.substr(3, 2);

      if (numbersOnlyTextValue.length > 5)
        this.formattedSocialSecurityNumber +=
          "-" + numbersOnlyTextValue.substr(5, 4);
    },
  },

  mounted: function () {
    this.formattedSocialSecurityNumber = this.value;
    this.formatText();
  },
};
</script>

<style lang="scss">
.social-security-number-text-field.is-participant-portal-field {
  .social-security-number-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
      padding-right: 25px;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
    position: relative;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }

  .v-input__append-inner {
    color: $neutral50 !important;
    margin-top: 10px !important;
  }

  .multi-line {
    white-space: pre-line;
  }

  .v-messages__message.message-transition-enter-to {
    margin-bottom: 1px;
  }
}

.social-security-number-text-field.is-general-field {
  .multi-line {
    white-space: pre-line;
  }
}
.social-security-number-text-field.is-account-holder-portal-field {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    min-height: 0px;
    margin-bottom: 0px;

    div {
      min-height: 0px;
    }

    .error--text {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .v-messages__wrapper {
      font-size: 10px;
    }
  }
}
</style>
