<template>
  <div
    :class="[
      'postal-code-text-field',
      isParticipantPortalField
        ? 'is-participant-portal-field'
        : 'is-general-field',
      { 'is-account-holder-portal-field': isAccountHolderField },
    ]"
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="postal-code-text-field-header-style"
    >
      <span v-if="required && !isAccountHolderField" class="error-text">*</span
      >{{ header }}
    </div>
    <v-text-field
      :id="id"
      v-model="postalCode"
      :label="
        isParticipantPortalField
          ? ''
          : label + (required || hideOptionalText ? '' : ' (optional)')
      "
      :placeholder="placeholder"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :clearable="clearable"
      :dense="dense"
      :validate-on-blur="true"
      :color="$colors.penChecksTeal"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Postal Code",
    },
    placeholder: {
      type: String,
      default: "Postal Code",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    countryCode: {
      type: String,
      default: "US",
    },
    hideOptionalText: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Postal Code",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
    errorLabel: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      postalCode: this.value,
      rules: [
        () => {
          if (
            this.countryCode &&
            this.countryCode.trim() != "" &&
            this.countryCode.toUpperCase().trim() != "US"
          )
            return true;

          if (
            !this.required &&
            (!this.postalCode || this.postalCode.trim() == "")
          )
            return true;

          return this.postalCode && /^\d{5}(-\d{4})?$/.test(this.postalCode)
            ? true
            : this.isAccountHolderField
            ? this.errorLabel
            : "Please enter a valid US postal code";
        },

        () => {
          if (this.countryCode && this.countryCode.toUpperCase().trim() == "US")
            return true;

          if (
            !this.required &&
            (!this.postalCode || this.postalCode.trim() == "")
          )
            return true;

          return this.postalCode && this.postalCode.length > 3
            ? true
            : "Please enter a valid postal code";
        },
        (v) => (this.required ? !!v : true) || "Field cannot be left blank",
      ],
    };
  },

  watch: {
    postalCode() {
      this.$emit("input", this.postalCode);
    },
    value() {
      this.postalCode = this.value;
    },
  },

  mounted: function () {
    this.postalCode = this.value;
  },
};
</script>

<style lang="scss">
.postal-code-text-field.is-participant-portal-field {
  .postal-code-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
.postal-code-text-field.is-account-holder-portal-field {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    min-height: 0px;
    margin-bottom: 0px;

    div {
      min-height: 0px;
    }

    .error--text {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .v-messages__wrapper {
      font-size: 10px;
    }
  }
}
</style>
