var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('v-dialog',{class:_vm.modalClasses,attrs:{"max-width":_vm.maxWidth,"height":_vm.height,"persistent":_vm.persistent,"content-class":'singleton-modal ' + (_vm.draggable ? ' draggable ' : '') + _vm.contentClass,"scrollable":"","attach":_vm.attach},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},[_c('v-card',{class:{ 'pc-modal-card': true, 'pc-modal-spaced-columns': true }},[(_vm.withCatchBar)?_c('div',{staticClass:"pc-modal-catch-bar",style:('background-color:' +
          (_vm.catchBarColor ? _vm.catchBarColor : _vm.$colors.penChecksOrange) +
          ';')}):_vm._e(),(_vm.closable)?_c('pc-button',{staticClass:"pa-0",staticStyle:{"position":"absolute","right":"5px","top":"10px"},attrs:{"text":"","small":"","id":_vm.setId('CloseButton')},on:{"click":_vm.close}},[_c('pc-icon',{attrs:{"name":"pc-close-x","color":_vm.$colors.penChecksMediumGray,"size":"26","light":""}})],1):_vm._e(),(_vm.title)?_c('v-card-title',{class:{
          headline: true,
          'pc-modal-title-with-subtitle': _vm.subtitle,
          'd-flex justify-center': _vm.centerTitle,
        },style:('color: ' + _vm.$colors.penChecksDarkGray),attrs:{"id":_vm.setId('Title')}},[(_vm.icon)?_c('span',{class:'mr-2 fas fa-' + _vm.icon,style:('color:' + _vm.iconColor)}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.subtitle)?_c('div',{staticClass:"pc-modal-subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]):_vm._e(),_c('v-card-text',{staticClass:"px-10 pb-5",class:{
          'pt-10': !_vm.title,
        },staticStyle:{"max-height":"500px"}},[_c('pc-error-alert',{attrs:{"api-response":_vm.apiResponse,"errors":_vm.errors,"error":_vm.error}}),_vm._t("default")],2),_c('v-card-actions',{staticClass:"pc-modal-actions",class:{
          'mx-auto': _vm.centerButtons,
        }},[_c('div',[_vm._t("modal-buttons-description")],2),_c('v-spacer'),_c('div',[_vm._t("modal-buttons")],2)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }