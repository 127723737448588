import Vue from "vue";

Number.numberFormatHelper = function (value, options) {
  if (typeof value == "undefined" || value == null) value = "";

  value = value.toString().trim();

  var isNegative =
    value.startsWith("-") || value.startsWith("$-") || value.startsWith("-$");

  value = value.replace(/[^\d.]/g, "");

  if (value != "") {
    let values = value.split(".");

    if (values[0] == "") values[0] = 0;

    value = parseInt(values[0]) + "";
    if (values.length > 1 && values[1].trim() != "")
      value += "." + values[1].trim();
  }

  if (isNegative) value = "-" + value;

  var dollarSign =
    options && typeof options.dollarSign != "undefined"
      ? options.dollarSign
      : false;
  var force2Decimals =
    options && typeof options.force2Decimals != "undefined"
      ? options.force2Decimals
      : false;
  var limit2Decimals =
    options && typeof options.limit2Decimals != "undefined"
      ? options.limit2Decimals
      : false;
  var commas =
    options && typeof options.commas != "undefined" ? options.commas : false;
  var stripDecimals =
    options && typeof options.stripDecimals != "undefined"
      ? options.stripDecimals
      : false;

  if (!value) {
    value = dollarSign ? "$0" : "0";
    value = force2Decimals && !stripDecimals ? value + ".00" : value;
    return value;
  }

  var parts = value.toString().split(".");

  var wholeNumber = parts[0]; //.replace(/\$/g, "").replace(/\,/g, "")
  var decimals = parts.length > 1 ? parts[1] : "";
  if (force2Decimals) {
    decimals = "00";
    if (parts.length > 1) {
      if (parts[1].length >= 2) {
        decimals = parts[1].substr(0, 2); // If the string length of the decimals is more than 2, then crop it to 2 only (no rounding)
      } else if (parts[1].length == 1) {
        decimals = parts[1] + "0"; // If the string length is 1, then we need to add a zero to the end for consistency
      }
    }
  }

  if (commas) {
    var newNumber = "";
    for (var i = 0; i < wholeNumber.length; i++) {
      var number = wholeNumber.charAt(wholeNumber.length - (i + 1));
      newNumber =
        ((i + 1) % 3 == 0 && i != wholeNumber.length - 1
          ? "," + number
          : number) + newNumber;
    }
    wholeNumber = newNumber;
  }

  var returnValue = dollarSign ? "$" + wholeNumber : wholeNumber;
  returnValue =
    decimals.length > 0 && !stripDecimals
      ? returnValue + "." + decimals
      : returnValue;

  if (limit2Decimals) {
    let values = returnValue.split(".");
    returnValue = values[0];
    if (values.length > 1) returnValue += "." + values[1].substr(0, 2);
  }

  if (returnValue.startsWith("$-")) returnValue = "-$" + returnValue.substr(2);
  if (returnValue.startsWith("-$,")) returnValue = "-$" + returnValue.substr(3);
  if (returnValue.startsWith("$,")) returnValue = "$" + returnValue.substr(2);

  if (returnValue.startsWith("-,")) returnValue = "-" + returnValue.substr(2);

  return returnValue;
};

Vue.filter("currency", function (value) {
  return Number.currencyString(value);
});

Vue.filter("numberWithCommas", function (value) {
  return Number.withCommas(value);
});

Vue.filter("percentage", function (value) {
  value = Math.roundProperly(value * 100, 2);
  return value + "%";
});

Vue.filter("ordinalSuffix", function (i) {
  if (isNaN(i)) return i;
  i = parseInt(i);
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
});

Number.currencyString = function (value) {
  return Number.numberFormatHelper(value, {
    dollarSign: true,
    force2Decimals: true,
    commas: true,
  });
};

Number.withCommas = function (value) {
  return Number.numberFormatHelper(value, { commas: true });
};

Number.currencyNumber = function (value) {
  return parseFloat(Number.numberFormatHelper(value));
};

Math.roundProperly = function (num, places) {
  let val = +(Math.round(num + "e+" + places) + "e-" + places);
  return +val.toFixed(places);
};

Math.currencyToWholeNumber = function (value) {
  return Math.roundProperly(+(value + "e+2"), 0);
};

Math.wholeNumberToCurrency = function (value) {
  return Math.roundProperly(+(value + "e-2"), 2);
};

Math.safeCurrencyValue = function (value) {
  return Math.wholeNumberToCurrency(Math.currencyToWholeNumber(value));
};

Math.subtractAmounts = function (minuend, ...subtrahends) {
  var difference = Math.currencyToWholeNumber(minuend);
  for (var subtrahend of subtrahends) {
    difference -= Math.currencyToWholeNumber(subtrahend ? subtrahend : 0);
  }

  return Math.wholeNumberToCurrency(difference);
};

Math.addAmounts = function (...summands) {
  var sum = 0;
  for (var summand of summands) {
    sum += Math.currencyToWholeNumber(summand ? summand : 0);
  }

  return Math.wholeNumberToCurrency(sum);
};
